$primary-color: #ED0000;
$secondary-loader-color: #ED0000;
$chart-accent-color: #008000;
$primary-light-color: #f89999;

// Custom Statements colors
$primary-color-chip-v2: rgba(0, 0, 0, 0.88);
$primary-light-color-chip-v2: #e6e6e6;
$primary-light-color-chip-hover-v2: #e0e0e0;

$active-menu-weight: 700;
$active-menu-color: #000000E0;

$forecast-chart-actuals-color: #000000;

$date-picker-hover: rgb(255,198,198);

